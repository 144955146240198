<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasCatalogDetail = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasCatalogDetail";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasCatalogDetail;
}>();

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const catalogData = (props.content.slots?.[0] as any).config?.catalog?.value ?? {};
</script>

<!-- TODO: Checks for Variable content should be added -->
<template>
  <CmsElementTeaserCategoryWide :title="catalogData.title" :text="catalogData.text" position="right" class="overflow-hidden">
    <div class="flex items-center justify-center w-full h-full">
      <div class="max-w-[70%]	shadow-2xl" style="transform: perspective(130px) rotateX(2deg) rotateY(-1deg) rotateZ(10deg) translateY(-30px) scale(0.95);">
        <SharedCmsImage :src="catalogData.cover.url" :crop="catalogData.crop" width="600" />
        <div class="absolute top-0 left-0 w-full h-full opacity-80" style="background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAKlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAKE86IAAAADnRSTlMAAQ0SEBECBwsFDwkDBN0J5q8AAALvSURBVHheddNPq910AofxT/JLTpJz7oG03opVCrF2Y9tFBPEWq5DW+gd1cSy2SLtJFbuwm5RLpYJCioI6gxAdGFwoRKwOzurMYq4iCnGjLlwcQVDfjVuV9h18v8/Do/XJyFvaqNJrCcmhVWBkp3Y9Pr32NFpVuo4p2KVt/zc5Oeen1xidGqxSq+1GjGJ6+83m6iMOkw5ZV+qNBBefDGLGod1R/TuKfzuQ0I1+UYXqHLOw1Ij3nTVVraojgCg9ut+9/Oir983M74aDywzjZ8qmZBZR0H7B/bW1Ks+w92JVmuWmupRAb3OyPk1lC4b/nvNdJdLHcXkpY0eQzIZQHkklYMoXnEtVN9StvTkBaRixvLvYQPbu1hxPEfX3ID1mY6Y/qlxF0Z9eOND2r8jRuWSzDA+pS6i92Gb7GxdL8SxlWMa+Xs1zSq1vJwsevqQdQnQ+n416MobZot00tZvQx5FoGYmdxDOh3fFolr6+irgvMhRHcO2cW0rL1hdKKnXs9zrr35vKBMVHbF7wqcT5WqoecTC1zM94WZ01J6hK5aZUhbV0vhjzl2phRjDy8/gxuD5MI0nPXdGavw768OqF1+K8kYtZVcVmrd/99biy3BRWI/0d3hA1gnhF57g5kSvauutKmjxKLxcIDTIhlkjsByjD39G6lbQr78TRwjQ141ZMLApEc5XYEA29xr9EklayvFFPxSy3vtR9OYSrhvWuhe3svn5QPy+xWbTCdOEHG1X8/8sMpJZBFfXoGzrSekikeYitNMw7Vp+fgu0+KghxndQPzotATFeKlau8U/8jBmewra7ex3MuK9OtUcfeq0nWbCu0e0u4mUatpKbT11BjH26X9xPVNTM6K9MAFs82osMrNfGhkkdOeGwaSw18/zga0al8T1qSfdAGBndm/3TFbct2m0i3J2RzhkXRESLJf7p9mi0dHBg4u17S5vEYpcqSmtvjfABhV9YfOSYZmYldVDHQEDUhLkqTWeob3ea8Iqj+ABsYv/FfSJpIAAAAAElFTkSuQmCC')"></div>
        <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-white/0 from-5% via-white/20 via-20% to-white/0 to-50%"></div>
        <div class="absolute top-1.5 left-1.5 w-full h-full bg-black/8 z--1 shadow-xl"></div>
      </div>
    </div>

    <template #buttons>
      <ul class="mt-6 flex flex-wrap gap-4">
        <li v-if="catalogData.link">
          <SharedFormButton
            type="button"
            layout="primary"
            @click="openModal"
          >
            Im Katalog Blättern
          </SharedFormButton>
        </li>
        <li v-if="catalogData?.pdf?.url">
          <SharedFormButton
            type="link"
            layout="secondaryDark"
            :to="catalogData.pdf.url"
            download
          >
            PDF herunterladen
          </SharedFormButton>
        </li>
      </ul>
    </template>
  </CmsElementTeaserCategoryWide>

  <div class="s-gutter mt-20" v-if="!catalogData.notAvailable">
    <SharedFormCompositionCatalogOrder class="c-width-small" :orderId="catalogData.orderId" />
  </div>

  <Teleport to="body" v-if="catalogData.link">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isModalOpen"
        class="fixed top-0 z-300 p-6 w-screen h-screen backdrop-blur"
      >
        <div class="flex justify-end pt-6 pr-6 pb-2 bg-white rounded-t-lg">
          <a
            href="#"
            @click="closeModal"
            class="bg-maas-background-grey-medium flex h-8 w-8 items-center justify-center rounded-full text-sm"
          >✕</a>
        </div>
        <iframe :src="catalogData.link" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-downloads allow-popups-to-escape-sandbox" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" class="w-full h-[calc(100%-64px)] rounded-b-lg"></iframe>
      </div>
    </Transition>
  </Teleport>
</template>
